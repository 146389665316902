.background {
	max-width: 1236px;
	width: 95%;
	margin: auto;
	border-radius: 25px;
	background-color: #2b3825;
	padding-top: 25px;
	padding-bottom: 25px;
}

.servicesHeader {
	max-width: 1236px;
	font-size: 32px;
	color: white;
	margin: auto;
	margin-bottom: 25px;
}

.products {
	margin: 0 auto 50px auto;
	display: flex;
	flex-direction: column;
}

.productsHeader {
	text-align: left;
	max-width: 1236px;
	font-size: 32px;
	color: #2b3825;
	margin: auto;
	margin-bottom: 35px;
	margin-top: 35px;
	max-width: 1236px;
}

.service-container {
	display: flex;
	width: 750px;
	height: 300px;
	justify-content: center;
	margin: auto;
}

.service-details {
	display: flex;
	flex-direction: column;
	padding-left: 15px;
	justify-content: center;
}

.service-Name {
	color: white;
	font-size: 20px;
	margin-top: 5px;
	letter-spacing: 1px;
	text-align: left;
}

.service-description {
	color: white;
	text-align: left;
	margin-bottom: 35px;
	font-size: 14px;
}

.service-image {
	width: 250px;
	height: 250px;
	border: white 2px solid;
	border-radius: 125px;
}

.carousel {
	margin: auto;
}

.service-actions {
	text-align: left;
	display: flex;
	gap: 15px;
	align-items: center;
}

.service-price-info {
	color: #fff;
	margin-bottom: 0;
	text-align: start;
	margin-bottom: 1rem;
}

.see-treatment-link {
	color: #2b3825;
	text-decoration: none;
}

.see-treatment-link:hover {
	color: #2b3825;
}

.service-book {
	height: 38px;
	align-self: center;
	border-radius: 25px !important;
}

.details-button {
	text-decoration: none;
	color: white;
}

@media screen and (min-width: 1000px) {
	.carousel {
		width: 1000px;
	}
}

@media screen and (max-width: 1000px) {
	.service-container {
		flex-direction: column;
		width: 75%;
		align-items: center;
		height: max-content;
	}
}
