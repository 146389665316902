.trading-since{
    font-size: 16px;
    color: #2b3825;
    opacity: 0.6;
    text-align: left;
    margin-bottom: 5px;
    margin-left: 2px;
}

.company-intro{
    font-size: 50px;
    font-weight: 100;
    color: #2b3825;
    text-align: left;
    margin: 0;
}

.intro-text{
    flex-grow: 1;
    padding-left: 25px;
    align-self: center;
}
    
.logo{
    width: 250px;
    height: 250px;
    margin-right: 25px;
}

@media screen and (max-width: 600px) {
    .intro-text{
        display: none;
    }

    .logo{
        margin: auto;
    }
}