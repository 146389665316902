.services-container {
	display: flex;
	flex-direction: column;
	margin-top: 100px;
	text-align: left;
	flex-grow: 1;
	opacity: 0;
}

.title-section {
	color: #fff;
	max-width: 1236px;
	width: 100%;
	margin: auto;
	padding: 25px 25px 25px 25px;
	width: 95%;
	border-radius: 25px;
	background-color: #2b3825;
	text-align: center;
}

.services-list {
	max-width: 1236px;
	margin: 50px auto auto auto;
	display: flex;
	flex-direction: column;
	color: #2b3825;
}

.treatment-link {
	background: #2b3825;
	color: #fff;
	text-decoration: none;
	width: 120px;
	padding: 5px;
	border-radius: 15px;
	font-weight: 100;
	text-align: center;
}

.treatment-link:hover {
	color: #fff;
}

.treatment-button {
	padding: 0 !important;
	width: 120px;
	height: 38px;
}

.see-treatment-container {
	display: flex;
	flex-direction: row;
	align-items: center;
	gap: 25px;
	font-weight: bold;
}

.s-container {
	display: flex;
	flex-direction: row;
	margin-bottom: 75px;
	width: 85%;
	align-self: end;
	margin-right: 40px;
}

.reverse {
	align-self: start;
	margin-left: 50px;
}

.reverse .s-info {
	padding-right: 175px;
	padding-left: 25px;
}

.reverse .s-details .s-image {
	right: -100px;
	left: auto;
}

.background-list-text {
	position: absolute;
	color: rgb(255, 255, 255, 0.5);
	font-family: 'Dancing Script';
	left: 38%;
	top: 12%;
	font-size: 161px;
}

.reverse .s-info .background-list-text {
	left: 24%;
}

.s-info {
	text-align: left;
	padding: 25px 25px 0px 175px;
	align-self: center;
	background-color: rgb(101, 132, 87, 0.5);
	border-radius: 25px;
	min-height: 286px;
}

.s-info h3 {
	width: fit-content;
	border-bottom: 2px solid #2b3825;
	padding-bottom: 5px;
}

.s-info p {
	margin: 25px 0 25px 0;
	position: relative;
}

.s-details {
	padding: 0 25px 0 25px;
	align-self: center;
}

.s-image {
	position: absolute;
	width: 300px;
	height: 250px;
	border-radius: 25px;
	align-self: center;
	left: -100px;
	top: 17px;
}

@media screen and (max-width: 800px) {
	.s-container {
		display: flex;
		flex-direction: column;
		gap: 25px;
		width: 95%;
		align-self: center;
		margin-right: 0;
		margin-left: 0;
	}

	.s-image {
		position: initial;
	}

	.s-info {
		padding-left: 25px;
		padding-right: 25px;
	}

	.background-list-text,
	.reverse .s-info .background-list-text {
		position: absolute;
		top: 68%;
		left: 50%;
		transform: translate(-50%, -50%);
		font-size: 130px;
	}

	.reverse .s-info {
		padding-left: 25px;
		padding-right: 25px;
	}

	.reverse {
		flex-direction: column-reverse;
	}
}
