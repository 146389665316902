.about-us-page {
	height: 100%;
	overflow-x: hidden;
}

.about-us-container {
	display: flex;
	flex-direction: column;
	margin: 100px auto auto auto;
	height: 100%;
	width: 95%;
	text-align: left;
	flex-grow: 1;
	opacity: 0;
	background-color: #2b3825;
	border-radius: 25px;
}

.about-title {
	color: #fff;
	max-width: 1236px;
	width: 100%;
	margin: auto;
	padding: 25px 25px 25px 25px;
	width: 95%;
	border-radius: 25px;
	text-align: center;
}

.about-section {
	padding: 0 25px 0 25px;
	color: #fff;
	display: flex;
	justify-content: start;
	max-width: 1236px;
	margin: auto;
	width: 100%;
}

.about-text {
	flex-grow: 1;
	padding: 50px 0 75px 0;
}

.portrait {
	border: white 2px solid;
	border-radius: 125px;
	height: 250px;
	width: 250px;
	margin-left: 50px;
	opacity: 0;
	object-fit: cover;
}

@media screen and (max-width: 800px) {
	.about-section {
		flex-direction: column-reverse;
	}

	.portrait {
		margin: auto;
	}
}
