.nav-container-desktop{
    display: flex;
    position: fixed;
    left: 50%;
    transform: translate(-50%, 0);
    width: 100%;
    background-color: white;
    z-index: 100000;
}

.nav-containter-mobile{
    display: none;
    flex-direction: row-reverse;
    width: 100%;
    display: flex;
    position: fixed;
    background-color: white;
    z-index: 10000;
}

.nav-items{
    max-width: 1236px;
    display: flex;
    width: 100%;
    margin: auto;
}

.navbar-ul{
    list-style-type: none;
    padding-left: 200px;
    overflow: hidden;
    width: fit-content;
    margin: auto;
}

.navbar-ul-mobile{
    list-style-type: none;
    width: fit-content;
    position: absolute;
    left: -250px;
    transition: left .25s ease-in;
    top: 0;
    height: 100%;
    background: #fff;
    z-index: 10000;
    padding-right: 2rem;
}

.navbar-ul-mobile li a{
    display: inline-block;
    margin: 14px 16px;
    padding: 10px;
    font-size: 18px;
    cursor: pointer;
    text-decoration: none;
    color: #2b3825;
}

.navbar-ul li {
    float: left;
}

.active-nav{
    background: #2b3825;
    color: #fff !important;
    box-shadow: 0 0 0 1px rgb(80 80 80 / 50%);
}

.navbar-ul li a {
    display: inline-block;
    margin: 10px 12px;
    padding: 10px;
    font-size: 18px;
    cursor: pointer;
    text-decoration: none;
    color: #2b3825;    
    border: 2px
}

.book-button {
    height: 38px;
    align-self: center;
    margin-right: 100px;
}

.toggle{
    left: 0;
    position: fixed;
}

.burger {
    width: 55px;
    height: 55px;
    border-radius: 10px;
    background: white;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 5px;
    transition: all .5s ease;
    margin: 1em;
}
.burger-strip {
    transition: all .5s ease;
}
.strip div {
    height: 3px;
    border-radius: 2px;
    background: #2b3825;
    margin: 10px;
    transition: all .55s cubic-bezier(0.075, 0.82, 0.165, 1);
    width: 40px;
}
.strip1-clicked {
    transform: translateY(13px) rotate(45deg);
}
.strip2-clicked {
    opacity: 0;
}
.strip3-clicked {
    transform: translateY(-13px) rotate(-45deg);
}

.navbar-ul li a, .navbar-ul-mobile li a{
    border-radius: 25px;
}

.navbar-ul li a:hover{
        background: #2b3825;
        color: #fff;
        box-shadow: 0 0 0 1px rgba(80, 80, 80, .5);
        transition: background-color 0.5s ease;
}

@media screen and (min-width: 850px) {
    .navbar-ul-mobile{
        left: -250px;
    }

    .nav-containter-mobile{
        display: none;
    }
}

@media screen and (max-width:850px) {
    .nav-container-desktop{
        display: none;
    }
    .nav-containter-mobile{
        display: flex;
    }
    .book-button{
        margin-right: 15px;
    }
}