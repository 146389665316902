.splash-screen {
	background-color: #2b3825;
	height: 100%;
	width: 100%;
	z-index: 1000000;
	position: absolute;
	left: 0;
	right: 0;
	margin-left: auto;
	margin-right: auto;
}

.splash-text {
	position: relative;
	color: #fff;
	opacity: 0;
	text-align: center;
	top: 50vh;
	font-family: 'Dancing Script';
	font-size: 58px;
}
