p {
	font-size: 16px;
}

.footer-banner {
	background-color: #fbfafb;
	min-width: 100%;
	height: 200px;
}

.footer-container {
	max-width: 1236px;
	display: flex;
	justify-content: space-between;
	margin: auto;
	padding: 25px;
}

.newsletter {
	align-self: center;
}

.title {
	font-size: 28px;
}

.newsletter-form {
	display: flex;
}

.subscribe-button {
	margin-left: 5px;
}

.contact {
	display: flex;
	flex-direction: column;
	align-self: center;
}

.icon-group {
	display: flex;
	cursor: pointer;
}

.social-username {
	margin-left: 5px;
}

.get-in-touch {
	line-height: 10px;
	align-self: center;
}

@media screen and (max-width: 800px) {
	.footer-banner {
		flex-direction: column-reverse;
		height: fit-content;
		gap: 25px;
	}

	.contact {
		flex-direction: row;
		gap: 25px;
	}

	.social-username {
		display: none;
	}
}
