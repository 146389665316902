.contact-page {
	text-align: left;
	max-width: 1236px;
	margin: 100px auto 25px auto;
	display: flex;
	padding: 0 25px 0 25px;
	justify-content: space-evenly;
}

.contact-form {
	margin-right: 25px;
	max-width: 40%;
	flex-grow: 1;
}

/* Disable up down arrows on number inputs */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
	-webkit-appearance: none;
	margin: 0;
}
input[type='number'] {
	-moz-appearance: textfield;
}

.submit {
	width: 100%;
	background-color: #2b3825;
}

.parking-info {
	margin-top: 1rem;
}

.map-frame {
	align-self: center;
}

.map {
	flex-grow: 1;
}

@media screen and (max-width: 800px) {
	.contact-page {
		flex-direction: column-reverse;
	}

	.contact-form {
		margin-top: 25px;
		max-width: 100%;
	}
}
