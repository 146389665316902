.back-button {
	position: absolute;
	top: 90px;
	left: 30px;
	background: #2b3825;
	color: #fff;
	text-decoration: none;
	width: 75px;
	padding: 5px;
	border-radius: 15px;
}

.info-image {
	position: static !important;
}

.back-button:hover {
	background-color: #25301f;
	color: #fff;
}

.service-info-intro {
	margin: 150px auto 125px auto;
	display: flex;
	flex-direction: row;
	padding: 0 25px 0 25px;
	max-width: 1236px;
}

.service-info-text {
	align-self: center;
	margin-left: 50px;
	text-align: left;
	color: #2b3825;
}

#book-service-container {
	display: flex;
	gap: 15px;
	align-items: center;
	margin-top: 25px;
}

#book-service-price {
	margin: 0;
	font-weight: bold;
}

.service-info-description {
	white-space: pre-line;
}

.service-info-text h1 {
	font-weight: bold;
}

.book-service {
	margin: 0;
}

.service-after-care {
	margin-top: 50px;
	color: #fff;
	background-color: #2b3825;
	padding: 25px;
}

#best-results-text {
	text-align: start;
	margin-top: 50px;
}

#best-results-list {
	margin-bottom: 50px;
}

.after-care-info {
	max-width: 1236px;
	margin: 50px auto;
	color: #2b3825;
	padding: 0 25px 0 25px;
}

.after-care-item:hover {
	transition: background-color 0.5s ease;
	background-color: #2b3825;
	color: #fff;
}

@media screen and (max-width: 800px) {
	.service-info-intro {
		flex-direction: column;
		align-items: center;
		gap: 25px;
	}
}
