.productGrid{
    width: 75%;
    align-self: center;
    max-width: 1000px;
}

.product-img{
    height: 175px;
    width: 175px;
    align-self: center;
    position: relative;
    object-fit: contain;
    border-radius: 50%;
    background-color: #fff;
}

.card-container{
    margin-bottom: 25px;
    background-color: transparent !important;
    flex-direction: row !important;
    border-color: #fff !important;
    min-height: 425px;
}

.card-body{
    min-height: 300px;
    background-color: #789d67;
    padding: 50px 25px 25px 25px !important;

    border-radius: 25px 25px 25px 25px;
    -moz-border-radius: 25px 25px 25px 25px;
    -webkit-border-radius: 25px 25px 25px 25px;

    -webkit-box-shadow: 2px 2px 20px -4px rgba(0,0,0,0.5);
    -moz-box-shadow: 2px 2px 20px -4px rgba(0,0,0,0.5);
    box-shadow: 2px 2px 20px -4px rgba(0,0,0,0.5);

    display: flex;
    flex-direction: column;
    justify-content: space-evenly;
}

.price-container{
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.basket-icon{
    margin-right: 10px;
}

.salon-tag{
    color: #fff;
}

.product-price{
    color: #fff;
    font-size: 20px;
}

.card-title, .card-text {
    color: white;
}

.card-text{
    font-size: 14px;
    display: flex;
    align-items: center;
    flex-grow: 1;
}

@media screen and (max-width: 950px) {
    .card-body{
        min-height: 478px;
    }
}

@media screen and (max-width: 750px) {
    .card-body{
        min-height: 300px;
    }
}